.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 20%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}
.contactus-container{
	margin-right: 10px;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding-left: 10px;
}


.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.row {
	display: flex;
	
	
	
	
}
.column {
	
	width: 100%;
	padding-right: 50px;
	
	
  }
  .row:after {
	content: "";
	display: table;
	clear: both;
  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
	.row {
	  flex-direction: column;
	  
	}
  }
