@import "../data/styles.css";




.item-icon {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    
	font-size: 30px;
	transition: color 0.2s ease-in-out;
	color:black;
   

}
.item-title {
    font-size: small;
}

.tech-items {
    display: flex;
    padding-right: 0px;
    padding-top: 10px;
    flex-flow: row wrap;
    border-radius: 10px ;
}
.tech-item-body {
    
    width: 125px;
    display: flex;
    margin: 5px;
	justify-content: left;
	align-items: center;
    border-radius: 10px;
    background-color:rgb(248, 248, 248)
    
}
