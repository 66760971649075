@import "../../../data/styles.css";




.works-body {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}


.work {
	display: flex;
	padding: 20px;
	width: 350px;
	flex-basis: auto;
	
}
.certificate {
	display: flex;
	padding-bottom: 20px;
	width: 350px;
	flex-basis: auto;
	
	
}

.work-image {
	height: 30px;
	width: 30px;
	object-fit: contain;
	
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	position: absolute;
	width: 350px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.work:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.work:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}
